import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-080adc2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title", "onClick"]
const _hoisted_2 = ["hidden"]
const _hoisted_3 = { class: "section-label" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "section-description"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "form-section-container",
    style: _normalizeStyle({ borderBottom: $setup.props.section.df.hide_border ? 'none' : '' })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
				'form-section',
				$setup.hovered ? 'hovered' : '',
				$setup.store.selected($props.section.df.name) ? 'selected' : '',
			]),
      title: $props.section.df.fieldname,
      onClick: _withModifiers($setup.select_section, ["stop"]),
      onMouseover: _cache[3] || (_cache[3] = _withModifiers($event => ($setup.hovered = true), ["stop"])),
      onMouseout: _cache[4] || (_cache[4] = _withModifiers($event => ($setup.hovered = false), ["stop"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([
					'section-header',
					$props.section.df.label || $props.section.df.collapsible ? 'has-label' : '',
					$setup.collapsed ? 'collapsed' : '',
				]),
        hidden: !$props.section.df.label && $setup.store.read_only
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["EditableInput"], {
            text: $props.section.df.label,
            placeholder: _ctx.__('Section Title'),
            modelValue: $props.section.df.label,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.section.df.label) = $event))
          }, null, 8 /* PROPS */, ["text", "placeholder", "modelValue"]),
          ($props.section.df.collapsible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "collapse-indicator",
                innerHTML: _ctx.frappe.utils.icon($setup.collapsed ? 'down' : 'up-line', 'sm')
              }, null, 8 /* PROPS */, _hoisted_4))
            : _createCommentVNode("v-if", true)
        ]),
        (!$setup.store.read_only)
          ? (_openBlock(), _createBlock($setup["Dropdown"], {
              key: 0,
              options: $setup.options,
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["options"]))
          : _createCommentVNode("v-if", true)
      ], 10 /* CLASS, PROPS */, _hoisted_2),
      ($props.section.df.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($props.section.df.description), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(["section-columns", {
					hidden: $props.section.df.collapsible && $setup.collapsed,
					'has-one-column': $props.section.columns.length === 1,
				}])
      }, [
        _createVNode($setup["draggable"], {
          class: "section-columns-container",
          modelValue: $props.section.columns,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($props.section.columns) = $event)),
          group: "columns",
          "item-key": "id",
          delay: $setup.is_touch_screen_device() ? 200 : 0,
          animation: 200,
          easing: $setup.store.get_animation,
          disabled: $setup.store.read_only
        }, {
          item: _withCtx(({ element }) => [
            _createVNode($setup["Column"], {
              section: $props.section,
              column: element,
              "data-is-user-generated": $setup.store.is_user_generated_field(element)
            }, null, 8 /* PROPS */, ["section", "column", "data-is-user-generated"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "delay", "easing", "disabled"])
      ], 2 /* CLASS */)
    ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_1)
  ], 4 /* STYLE */))
}