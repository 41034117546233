import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "field-controls"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control frappe-control", { editable: $setup.in_preview }])
  }, [
    _createCommentVNode(" label "),
    ($setup.in_preview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label"),
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["control-label label", { reqd: $props.df.reqd }])
        }, _toDisplayString($props.df.label), 3 /* TEXT, CLASS */)),
    _createCommentVNode(" input "),
    _createElementVNode("div", {
      style: _normalizeStyle({ pointerEvents: $setup.in_preview ? 'none' : 'auto' }),
      ref: "fieldWrapper"
    }, null, 4 /* STYLE */),
    _createCommentVNode(" description "),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}