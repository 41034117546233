import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43f9e43e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "field-label" }
const _hoisted_3 = {
  key: 0,
  class: "reqd-asterisk"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["hidden"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["title"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["title", "onClick"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["title"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["title", "onClick"]
const _hoisted_15 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['field', $setup.selected ? 'selected' : $setup.hovered ? 'hovered' : '']),
    title: $props.field.df.fieldname,
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.store.form.selected_field = $props.field.df), ["stop"])),
    onMouseover: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.hovered = true), ["stop"])),
    onMouseout: _cache[3] || (_cache[3] = _withModifiers($event => ($setup.hovered = false), ["stop"]))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.component), {
      df: $props.field.df,
      "is-customize-form": $setup.store.is_customize_form,
      "data-fieldname": $props.field.df.fieldname,
      "data-fieldtype": $props.field.df.fieldtype
    }, {
      label: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["EditableInput"], {
            ref: "label_input",
            text: $props.field.df.label,
            placeholder: _ctx.__('Label'),
            empty_label: `${_ctx.__('No Label')} (${$props.field.df.fieldtype})`,
            modelValue: $props.field.df.label,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.field.df.label) = $event))
          }, null, 8 /* PROPS */, ["text", "placeholder", "empty_label", "modelValue"]),
          ($props.field.df.reqd)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "*"))
            : _createCommentVNode("v-if", true),
          ($props.field.df.documentation_url)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "help-icon",
                innerHTML: _ctx.frappe.utils.icon('help', 'sm')
              }, null, 8 /* PROPS */, _hoisted_4))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", {
          class: "field-actions",
          hidden: $setup.store.read_only
        }, [
          ($props.field.df.fieldtype === 'Link')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(["btn btn-xs btn-icon", $setup.is_filter_applied()]),
                onClick: $setup.edit_filters
              }, [
                _createElementVNode("div", {
                  innerHTML: _ctx.frappe.utils.icon('filter', 'sm')
                }, null, 8 /* PROPS */, _hoisted_6)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["AddFieldButton"], {
            ref: "add_field_ref",
            column: $props.column,
            field: $props.field
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.frappe.utils.icon('add', 'sm')
              }, null, 8 /* PROPS */, _hoisted_7)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["column", "field"]),
          ($props.column.fields.indexOf($props.field))
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-xs btn-icon",
                title: 
							_ctx.__('Move the current field and the following fields to a new column')
						,
                onClick: $setup.move_fields_to_column
              }, [
                _createElementVNode("div", {
                  innerHTML: _ctx.frappe.utils.icon('move', 'sm')
                }, null, 8 /* PROPS */, _hoisted_9)
              ], 8 /* PROPS */, _hoisted_8))
            : _createCommentVNode("v-if", true),
          _createElementVNode("button", {
            class: "btn btn-xs btn-icon",
            title: _ctx.__('Duplicate field'),
            onClick: _withModifiers($setup.duplicate_field, ["stop"])
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.frappe.utils.icon('duplicate', 'sm')
            }, null, 8 /* PROPS */, _hoisted_11)
          ], 8 /* PROPS */, _hoisted_10),
          ($props.field.df.fieldtype === 'Table' && $props.field.df.options)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "btn btn-xs btn-icon",
                onClick: $setup.open_child_doctype,
                title: _ctx.__('Edit the {0} Doctype', [$props.field.df.options])
              }, [
                _createElementVNode("div", {
                  innerHTML: _ctx.frappe.utils.icon('external-link', 'sm')
                }, null, 8 /* PROPS */, _hoisted_13)
              ], 8 /* PROPS */, _hoisted_12))
            : _createCommentVNode("v-if", true),
          _createElementVNode("button", {
            class: "btn btn-xs btn-icon",
            title: _ctx.__('Remove field'),
            onClick: _withModifiers($setup.remove_field, ["stop"])
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.frappe.utils.icon('remove', 'sm')
            }, null, 8 /* PROPS */, _hoisted_15)
          ], 8 /* PROPS */, _hoisted_14)
        ], 8 /* PROPS */, _hoisted_5)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["df", "is-customize-form", "data-fieldname", "data-fieldtype"]))
  ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_1))
}