import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "field-controls"
}
const _hoisted_2 = {
  key: 1,
  class: "control-label label"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control", { editable: $setup.slots.label }])
  }, [
    _createCommentVNode(" label "),
    ($setup.slots.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label"),
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.__($props.df.label)), 1 /* TEXT */)),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "mt-2",
          innerHTML: $props.df.options
        }, null, 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" description "),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}